import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.listing_item)?_c('div',{staticClass:"listing-full",staticStyle:{"position":"relative","display":"flex","flex-direction":"column","align-items":"center","overflow":"hidden"},style:(_vm.liningStyles(_vm.listing_item) +
      "margin-top:" + (_vm.listing_item.img.length ? '0' : ((+_vm.landing.branding.topMenu.styles.size * 2.5) + "px")) + ";min-height:300px;")},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.listing_item.img.includes('1')),expression:"listing_item.img.includes('1')"}],style:(("width:" + (_vm.landing.branding.lining_margin ? '330px' : '345px') + ";height:" + (_vm.item.styles.detail_listing_img_height) + "px;overflow:hidden;"))},[_c('div',{staticClass:"preview_detail",staticStyle:{"height":"100%","overflow":"hidden"},style:(("width:" + (_vm.landing.branding.lining_margin ? '330px' : '345px') + ";"))})]),(_vm.listing_item.img.length && !_vm.listing_item.img.includes('1'))?_c('swiper',{ref:"mySwiper",staticStyle:{"width":"100%","overflow":"hidden","position":"relative"},style:(("height:" + (_vm.item.styles.detail_listing_img_height) + "px;")),attrs:{"direction":'horizontal',"pagination":{ clickable: true },"options":_vm.swiperOptionsListing},on:{"slideChange":_vm.changeSwiperIndex}},[_vm._l((_vm.listing_item.img),function(img){return _c('swiper-slide',{key:img},[_c('img',{style:(("height:" + (_vm.item.styles.detail_listing_img_height) + "px;width:" + (_vm.landing.branding.lining_margin ? '330px' : '345px') + ";")),attrs:{"src":img,"alt":""}})])}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2):_vm._e(),(
        _vm.listing_item.video_link &&
        _vm.listing_item.img.length &&
        _vm.activeIndex === 0
      )?_c('div',{staticClass:"listing__play_video listing__play_video_with_img",style:(("top:" + (+_vm.item.styles.detail_listing_img_height / 2 - 25) + "px;position:absolute;z-index:100;")),on:{"click":function($event){return _vm.openVideo(_vm.listing_item.video_link)}}}):_vm._e(),(_vm.listing_item.video_link && _vm.listing_item.img.length === 0)?_c('div',{staticClass:"listing__play_video listing__play_video_without_img",on:{"click":function($event){return _vm.openVideo(_vm.listing_item.video_link)}}}):_vm._e(),_c('div',{staticClass:"detail_description",staticStyle:{"height":"auto","min-height":"100px","width":"100%","padding":"10px 20px"},domProps:{"innerHTML":_vm._s(_vm.htmlData(_vm.listing_item.full_descr))}}),(_vm.content.styles.show_open_map_button)?_c('div',{staticClass:"canvas-socials",staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('a',{directives:[{name:"external-link",rawName:"v-external-link"}],staticClass:"canvas-socials__item",style:("color:#000 !important;background-color:#eeeeee;border-radius: 15px;"),attrs:{"href":("http://maps.google.com/maps?q=" + (_vm.listing_item.map.lat) + "," + (_vm.listing_item.map.lng)),"target":"_blank"}},[_c('span',{staticClass:"canvas-socials__icon"},[_c(VIcon,[_vm._v("mdi-google-maps")])],1),_vm._v(" Directions with google maps ")])]):_vm._e(),(_vm.content.styles.show_map)?_c('GmapMap',{ref:"gmap",staticStyle:{"width":"100%","height":"300px"},attrs:{"zoom":_vm.content.styles.zoom,"center":{ lat: +_vm.listing_item.map.lat, lng: +_vm.listing_item.map.lng },"map-type-id":"terrain"}},[_c('GmapMarker',{attrs:{"position":{
      lat: +_vm.listing_item.map.lat,
      lng: +_vm.listing_item.map.lng,
    },"clickable":true}})],1):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }